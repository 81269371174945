import React from 'react';

export default function Footer({ space }) {
    return (
        <>
            <footer className='footer-section'>
                <div
                    className={`footer-top background-img-2 ${
                        space ? 'pt-150' : 'pt-60'
                    }`}
                    style={{
                        background:
                            "url('assets/img/footer-bg.png') no-repeat center top / cover",
                    }}
                >
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0'>
                                <div className='footer-nav-wrap text-white'>
                                    <img
                                        src='assets/img/lumi/lumi-logo-white.png'
                                        alt='footer logo'
                                        width='120'
                                        className='img-fluid mb-3'
                                    />
                                    <p>
                                        Somos tu excusa para aprender algo
                                        nuevo, todos los días.
                                    </p>

                                    <div className='social-list-wrap'>
                                        <ul className='social-list list-inline list-unstyled'>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='https://www.linkedin.com/company/getlumi'
                                                    target='_blank'
                                                    title='linkedin'
                                                >
                                                    <span className='ti-linkedin'></span>
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a
                                                    href='https://www.facebook.com/getlumiapp'
                                                    target='_blank'
                                                    title='Facebook'
                                                >
                                                    <span className='ti-facebook'></span>
                                                </a>
                                            </li>
                                            {/*  <li className='list-inline-item'>
                                                <a
                                                    href='#/'
                                                    target='_blank'
                                                    title='Twitter'
                                                >
                                                    <span className='ti-twitter'></span>
                                                </a>
                                            </li> */}
                                            <li className='list-inline-item'>
                                                <a
                                                    href='https://www.instagram.com/getlumi_/'
                                                    target='_blank'
                                                    title='Instagram'
                                                >
                                                    <span className='ti-instagram'></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-8'>
                                <div className='row'>
                                    <div className='col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0'></div>
                                    <div className='col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0'></div>
                                    <div className='col-sm-6 col-md-4 col-lg-4'>
                                        <div className='footer-nav-wrap text-white'>
                                            <h5 className='mb-3 text-white'>
                                                Contacto
                                            </h5>
                                            <ul className='list-unstyled support-list'>
                                                {/* <li className='mb-2 d-flex align-items-center'>
                                                    <span className='ti-location-pin mr-2'></span>
                                                    Avenida Medrano 1020
                                                    <br />
                                                    Buenos Aires, Argentina.
                                                </li> */}
                                                {/* <li className='mb-2 d-flex align-items-center'>
                                                    <span className='ti-mobile mr-2'></span>
                                                    <a href='tel:+61283766284'>
                                                        {' '}
                                                        +54 9 11 2563 4661
                                                    </a>
                                                </li> */}
                                                <li className='mb-2 d-flex align-items-center'>
                                                    <span className='ti-email mr-2'></span>
                                                    <a href='mailto:mail@example.com'>
                                                        {' '}
                                                        cris@aprendeconlumi.com
                                                    </a>
                                                </li>
                                                <li className='mb-2 d-flex align-items-center'>
                                                    <span className='ti-world mr-2'></span>
                                                    <a href='#/'>
                                                        {' '}
                                                        www.aprendeconlumi.com
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='footer-bottom border-gray-light mt-5 py-3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 col-lg-7'>
                                    <div className='copyright-wrap small-text'>
                                        <p className='mb-0 text-white'>
                                            © Lumi 2023
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-5'>
                                    <div className='terms-policy-wrap text-lg-right text-md-right text-left'>
                                        <ul className='list-inline'>
                                            <li className='list-inline-item'>
                                                <a
                                                    className='small-text'
                                                    target='_blank'
                                                    href='https://docs.google.com/document/d/1UcEzlv1smKLDnMJ1DpqE46pABps7BCGLgi3IP-8qRKg/edit?usp=sharing'
                                                >
                                                    Términos y condiciones
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a
                                                    className='small-text'
                                                    target='_blank'
                                                    href='https://docs.google.com/document/d/1jdH7bO7gkuYAIAkLSGPveBl67IHR7W2cvXgnUuyY0vY/edit?usp=sharing'
                                                >
                                                    Política de privacidad
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
