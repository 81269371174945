import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../components/others/NotFound';
import HomeEight from '../themes/index-8/HomeEight';
import ScrollToTop from './ScrollToTop';

const AppRoutes = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<HomeEight />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
};

export default AppRoutes;
