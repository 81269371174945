import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ darkBg, classOption }) => {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const stickyheader = document.querySelector('.header');
        setHeaderTop(stickyheader.offsetTop);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <>
            <header className={`header ${classOption}`}>
                <nav
                    className={`navbar navbar-expand-lg fixed-top ${
                        darkBg ? 'bg-transparent' : 'custom-nav white-bg'
                    } ${scroll > headerTop ? 'affix' : ''}`}
                >
                    <div className='container'>
                        <Link to='/' className='navbar-brand'>
                            {darkBg ? (
                                <img
                                    src='assets/img/lumi/lumi-logo-white.png'
                                    width='80'
                                    alt='logo'
                                    className='img-fluid'
                                />
                            ) : (
                                <img
                                    src='assets/img/lumi/lumi-logo-color.png'
                                    width='80'
                                    alt='logo'
                                    className='img-fluid'
                                />
                            )}
                        </Link>
                        <button
                            className='navbar-toggler'
                            type='button'
                            data-toggle='collapse'
                            data-target='#navbarSupportedContent'
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                        >
                            <span className='ti-menu'></span>
                        </button>
                        <div
                            className='collapse navbar-collapse main-menu'
                            id='navbarSupportedContent'
                        >
                            <ul className='navbar-nav ml-auto'>
                                <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#beneficios'
                                    >
                                        Beneficios
                                    </HashLink>
                                </li>
                                <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#funcionalidades'
                                    >
                                        Funcionalidades
                                    </HashLink>
                                </li>
                                <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#curso'
                                    >
                                        Curso
                                    </HashLink>
                                </li>
                                {/*  <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#lumipro'
                                    >
                                        Membresía
                                    </HashLink>
                                </li>
                                <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#testimonios'
                                    >
                                        Testimonios
                                    </HashLink>
                                </li> */}
                                <li className='nav-item'>
                                    <HashLink
                                        className='nav-link'
                                        smooth
                                        to='#preguntas'
                                    >
                                        Preguntas
                                    </HashLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Navbar;
