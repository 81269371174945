// Internal dependencies
import React from 'react';

// External dependencies
import FeatureImgTwo from '../../components/features/FeatureImgTwo';
import Footer from '../../components/layout/footer/Footer';
import Navbar from '../../components/layout/header/Navbar';
import Layout from '../../components/layout/Layout';
import Subsribe from '../../components/newsletter/Subsribe';
import Screenshot from '../../components/screenshot/Screenshot';
import HeroEight from './HeroEight';
import AllReview from '../../components/testimonial/AllReview';
import Accordion from '../../components/Faqs/Accordion';
import Promo from '../../components/promo/Promo';
import AboutApp from '../../components/about/AboutApp';

export default function HomeEight() {
    return (
        <Layout>
            <Navbar classOption='custom-header' />
            <HeroEight />
            <Promo />
            <FeatureImgTwo />
            <Screenshot />
            {/* <AboutApp />
            <AllReview /> */}
            <Accordion Title />
            <Footer />
        </Layout>
    );
}
