import { Link } from 'react-router-dom';

export default function HeroEight() {
    return (
        <>
            <section id='#' className='hero-section hero-section-3 ptb-100'>
                <div className='circles'>
                    <div className='point animated-point-1'></div>
                    <div className='point animated-point-2'></div>
                    <div className='point animated-point-3'></div>
                    <div className='point animated-point-4'></div>
                    <div className='point animated-point-5'></div>
                    <div className='point animated-point-6'></div>
                </div>
                <div className='container' id='animation-area-1'>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                            <div className='hero-content section-title text-center text-lg-left my-5 mt-lg-0'>
                                <span className='text-uppercase color-primary font-weight-bold'>
                                    Llegó Lumi
                                </span>
                                <h1 className='font-weight-bold'>
                                    Aprende sobre Product Management en 5
                                    minutos
                                </h1>
                                <p className='lead'>
                                    Desarrolla tus habilidades de Product
                                    Management con pequeñas clases interactivas.
                                    ¡No te quedes atrás! 😉
                                </p>
                                <div className='download-btn'>
                                    <Link
                                        to='https://play.google.com/store/apps/details?id=com.getlumi.lumi'
                                        className='btn google-play-btn mr-3'
                                        target='_blank'
                                    >
                                        <span className='ti-android'></span>{' '}
                                        Google Play
                                    </Link>
                                    <Link
                                        to='https://apps.apple.com/us/app/lumi-aprende-con-microcursos/id1673590259'
                                        className='btn app-store-btn'
                                        target='_blank'
                                    >
                                        <span className='ti-apple'></span> App
                                        Store
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end'>
                            <div className='hero-img position-relative'>
                                <div className='image-bg-positioning'>
                                    <img
                                        src='assets/img/lumi/home.png'
                                        alt=''
                                        className='img-fluid'
                                    />
                                </div>
                                <div className='animation-item d-none d-md-block d-lg-block'>
                                    <div className='p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1'>
                                        <p className='gr-text-11 mb-0 text-mirage-2'>
                                            “Realmente adictivo, ¡pero en el
                                            buen sentido!”
                                        </p>
                                        <div className='small-card-img ml-3'>
                                            <img
                                                src='assets/img/lumi/vicky.png'
                                                alt=''
                                                width='80px'
                                                className='rounded-circle img-fluid'
                                            />
                                        </div>
                                    </div>
                                    <div className='p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2'>
                                        <div className='small-card-img mr-3 text-white'>
                                            <img
                                                src='assets/img/lumi/tori.jpeg'
                                                alt=''
                                                width='80px'
                                                className='rounded-circle img-fluid'
                                            />
                                        </div>
                                        <p className='gr-text-11 mb-0 text-black'>
                                            "Gracias a Lumi, ahora pude
                                            productivizar mis huecos libres”
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
