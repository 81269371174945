import React from 'react';

export default function Accordion({ Title }) {
    return (
        <>
            <section
                id='preguntas'
                className={`container accordions ${
                    Title ? 'pt-60 pb-100' : ''
                }`}
            >
                {Title ? (
                    <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                            <div className='section-heading mb-5'>
                                <h2>Preguntas frecuentes 💭</h2>
                                <p className='lead'>
                                    ¿Tienes algunas dudas generales sobre la
                                    plataforma? ¡Te dejamos algunas respuestas
                                    para que las puedas resolver ahora mismo!
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className='row'>
                    <div className='col-lg-6'>
                        <div
                            id='accordion-1'
                            className='accordion accordion-faq'
                        >
                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-1-1'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-1-1'
                                    aria-expanded='false'
                                    aria-controls='collapse-1-1'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>
                                        ¿Qué tipos de cursos puedo encontrar?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-1-1'
                                    className='collapse'
                                    aria-labelledby='heading-1-1'
                                    data-parent='#accordion-1'
                                >
                                    <div className='card-body'>
                                        <p>
                                            Por el momento, en Lumi encontrarás
                                            cursos relacionados a habilidades
                                            digitales. En específico: Product
                                            Management, Startups y UX.
                                            Agregaremos nuevas categorías a
                                            futuro.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-1-2'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-1-2'
                                    aria-expanded='false'
                                    aria-controls='collapse-1-2'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>
                                        ¿Los cursos tienen un costo?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-1-2'
                                    className='collapse'
                                    aria-labelledby='heading-1-2'
                                    data-parent='#accordion-1'
                                >
                                    <div className='card-body'>
                                        <p>
                                            Lumi en su versión gratuita te
                                            permite completar dos cursos al mes,
                                            mientras que su plan pago te ofrece
                                            cursos ilimitados. De todas formas,
                                            ¡contarás con un período de prueba
                                            de 30 días apenas crees tu cuenta!
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-1-3'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-1-3'
                                    aria-expanded='false'
                                    aria-controls='collapse-1-3'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>{' '}
                                        ¿Entregan certificado?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-1-3'
                                    className='collapse'
                                    aria-labelledby='heading-1-3'
                                    data-parent='#accordion-1'
                                >
                                    <div className='card-body'>
                                        <p>
                                            Por el momento los cursos de Lumi no
                                            cuentan con certificado.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div
                            id='accordion-2'
                            className='accordion accordion-faq'
                        >
                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-2-1'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-2-1'
                                    aria-expanded='false'
                                    aria-controls='collapse-2-1'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>
                                        ¿Puedo descargar los cursos?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-2-1'
                                    className='collapse'
                                    aria-labelledby='heading-2-1'
                                    data-parent='#accordion-2'
                                >
                                    <div className='card-body'>
                                        <p>
                                            Por el momento no es posible
                                            descargar los cursos, pero los
                                            usuarios inscriptos en la membresía
                                            Lumi Pro pueden acceder al material
                                            del curso por fuera de la App.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-2-2'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-2-2'
                                    aria-expanded='false'
                                    aria-controls='collapse-2-2'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>{' '}
                                        Cuentan con profesores o mentores?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-2-2'
                                    className='collapse'
                                    aria-labelledby='heading-2-2'
                                    data-parent='#accordion-2'
                                >
                                    <div className='card-body'>
                                        <p>
                                            Los cursos de Lumi consisten en
                                            contenido teórico + ejercicios que
                                            son creados por profesionales de la
                                            industria, pero como son cursos
                                            cortos, no hay necesidad de contar
                                            con profesores o tutores que te
                                            acompañen.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='card'>
                                <div
                                    className='card-header py-4'
                                    id='heading-2-3'
                                    data-toggle='collapse'
                                    role='button'
                                    data-target='#collapse-2-3'
                                    aria-expanded='false'
                                    aria-controls='collapse-2-3'
                                >
                                    <h6 className='mb-0'>
                                        <span className='ti-help-alt mr-3 color-secondary'></span>{' '}
                                        ¿Necesito conexión a Internet?
                                    </h6>
                                </div>
                                <div
                                    id='collapse-2-3'
                                    className='collapse'
                                    aria-labelledby='heading-2-3'
                                    data-parent='#accordion-2'
                                >
                                    <div className='card-body'>
                                        <p>
                                            ¡Sí! Por el momento no es posible
                                            descargar los cursos, por lo que
                                            necesitarás contar con una conexión
                                            a Internet para completar las clases
                                            y guardar tu progreso.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
