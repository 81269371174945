import React from 'react';

export default function FeatureImgTwo() {
    return (
        <>
            <section
                id='funcionalidades'
                className='about-us ptb-100 gray-light-bg'
            >
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-md-6'>
                            <div className='about-content-right'>
                                <img
                                    src='assets/img/lumi/features.png'
                                    alt='about us'
                                    className='img-fluid'
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='about-content-left section-heading'>
                                <div className='mb-5'>
                                    <h2>
                                        +140 clases sobre producto en tu
                                        bolsillo 🚀
                                    </h2>
                                </div>
                                <div className='d-flex align-items-start mb-45'>
                                    <div className='pr-4'>
                                        <div className='icon icon-shape icon-color-3 rounded-circle'>
                                            <span className='ti-star'></span>
                                        </div>
                                    </div>
                                    <div className='icon-text'>
                                        <h5>Contenido de calidad</h5>
                                        <p
                                            style={{ marginTop: '-6px' }}
                                            className='mb-0'
                                        >
                                            El contenido de los cursos está
                                            basado en experiencia profesional y
                                            los mejores libros del mercado.
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start mb-45'>
                                    <div className='pr-4'>
                                        <div className='icon icon-shape icon-color-3 rounded-circle'>
                                            <span className='ti-rocket'></span>
                                        </div>
                                    </div>
                                    <div className='icon-text'>
                                        <h5>Membresía PRO</h5>
                                        <p
                                            style={{ marginTop: '-6px' }}
                                            className='mb-0'
                                        >
                                            Con Lumi Pro, podrás acceder de
                                            forma ilimitada a cursos, a su
                                            material y a mentorías mensuales 1:1
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start mb-45'>
                                    <div className='pr-4'>
                                        <div className='icon icon-shape icon-color-3 rounded-circle'>
                                            <span className='ti-settings'></span>
                                        </div>
                                    </div>
                                    <div className='icon-text'>
                                        <h5>Configuración de perfil</h5>
                                        <p
                                            style={{ marginTop: '-6px' }}
                                            className='mb-0'
                                        >
                                            Edita tus datos, guarda cursos en
                                            favoritos y revisa tu progreso.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
