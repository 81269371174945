import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function Screenshot({ hasBg }) {
    const options = {
        loop: true,
        margin: 0,
        center: true,
        dots: true,
        nav: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 3,
            },
            991: {
                items: 4,
            },
            1200: {
                items: 4,
            },
            1920: {
                items: 4,
            },
        },
    };
    return (
        <>
            <section
                id='curso'
                className={`screenshots-section ptb-100 ${
                    hasBg ? 'gray-light-bg' : ''
                }`}
            >
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <div className='section-heading text-center'>
                                {hasBg ? (
                                    <span className='text-uppercase color-secondary sub-title'>
                                        El curso de Lumi
                                    </span>
                                ) : (
                                    ''
                                )}
                                <h2>El curso de Lumi 🙌🏻</h2>
                                <p className='lead'>
                                    La dosis justa de teoría y práctica. Aprende
                                    a partir de texto e imagenes para luegar
                                    realizar ejercicios que prueben tu
                                    conocimiento. Al terminar con una clase,
                                    ¡inmediatamente ya puedes comenzar con la
                                    siguiente!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='screen-slider-content mt-5'>
                        <div className='screenshot-frame'></div>
                        <OwlCarousel
                            className='screen-carousel owl-carousel owl-theme dot-indicator'
                            {...options}
                        >
                            <img
                                src='assets/img/lumi/1.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                            <img
                                src='assets/img/lumi/2.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                            <img
                                src='assets/img/lumi/3.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                            <img
                                src='assets/img/lumi/4.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                            <img
                                src='assets/img/lumi/5.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                            <img
                                src='assets/img/lumi/6.png'
                                className='img-fluid'
                                alt='screenshots'
                            />
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    );
}
