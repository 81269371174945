import React from 'react';

const Promo = () => {
    return (
        <>
            <section id='beneficios' className='promo-section ptb-20'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-7 col-md-8'>
                            <div className='section-heading text-center mb-5'>
                                <h2>¿Por qué Lumi? 🤔</h2>
                                <p className='lead'>
                                    Proponemos una forma de educación que se
                                    habitúe a los tiempos actuales de constantes
                                    distracciones y falta de tiempo. Con Lumi
                                    podrás aprender algo nuevo todos los días en
                                    cualquier momento y lugar.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row equal'>
                        <div className='col-md-4 col-lg-4'>
                            <div className='single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100'>
                                <div className='circle-icon mb-5'>
                                    <span className='ti-wand text-white'></span>
                                </div>
                                <h5>Descubre microcursos</h5>
                                <p>
                                    Nuestro contenido cubre conceptos
                                    importantes que puedas aprender con teoría y
                                    ejercicios.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 col-lg-4'>
                            <div className='single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100'>
                                <div className='circle-icon mb-5'>
                                    <span className='ti-book text-white'></span>
                                </div>
                                <h5>Clases enfocadas</h5>
                                <p>
                                    La dosis justa de teoría para que puedas
                                    absorber nuevos conceptos fácilmente.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 col-lg-4'>
                            <div className='single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100'>
                                <div className='circle-icon mb-5'>
                                    <span className='ti-hand-point-up text-white'></span>
                                </div>
                                <h5>Enfoque interactivo</h5>
                                <p>
                                    ¡No todo es teoría! Practica lo que
                                    aprendiste con ejercicios que prueben tu
                                    conocimiento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Promo;
